.coupon-text {
    font-family: "Lexend", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
    color: #7b809a;
    line-height: 0.9;

}

.coupon-text.invalid {
    color: #f00;

}