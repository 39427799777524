.container-loading {
    background-color: #0B2F36;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-loading img {
    width: 200px;

}

@media screen and (max-width: 600px) {

    /* .container-loading img {
        width: 100%;

    } */
}