/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* iframe#webpack-dev-server-client-overlay {
  display: none !important;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f0f1f3;
  /* border: 1px solid #DDCA92; */
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0b2f36;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0b1f36;
}

.fc .fc-button-primary {
  font-size: 14px;
  /* background-color: #0B1F36; */
}

.fc .fc-button-active {
  font-size: 14px;
  background-color: #0b1f36;
}

.fc-toolbar-title {
  color: #0b1f36;
  font-size: 18px !important;
  text-transform: capitalize;
}

.fc-timegrid-axis-cushion.fc-scrollgrid-shrink-cushion.fc-scrollgrid-sync-inner {
  font-size: 14px;
}

.fc-scrollgrid-section.fc-scrollgrid-section-body {
  overflow: hidden;
}
