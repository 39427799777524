.link-suport {
    width: 160px;
    border-radius: 15px;
    background-color: #0B2F36;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    margin: 0 5px 0 0;
}

.link-suport:hover {
    background-color: #DDCA92;
    color: #0B2F36;
}